<template>
  <div>
    <a-row class="title" type="flex" justify="space-between">
      <a-col>
        <h3>
          <img
            src="@/assets/images/dashboard/customers/opportunity.png"
            alt="travels"
            width="15"
          />
          Oportunidades
        </h3>
      </a-col>

      <a-col class="filters">
        <a-button
          :class="activeTab == '' ? 'active' : ''"
          @click="onClickChangeContractsStatus('')"
          type="link"
        >
          Todos
        </a-button>
        <a-divider type="vertical" />
        <a-button
          :class="activeTab == 'Aberto' ? 'active' : ''"
          @click="onClickChangeContractsStatus('Aberto')"
          type="link"
        >
          Aberto
        </a-button>
        <a-divider type="vertical" />
        <a-button
          :class="activeTab == 'Ganho' ? 'active' : ''"
          @click="onClickChangeContractsStatus('Ganho')"
          type="link"
        >
          Ganho
        </a-button>
        <a-divider type="vertical" />
        <a-button
          :class="activeTab == 'Perdido' ? 'active' : ''"
          @click="onClickChangeContractsStatus('Perdido')"
          type="link"
        >
          Perdido
        </a-button>
      </a-col>
    </a-row>

    <div v-if="!opportunity.loading">
      <div v-for="(opportunity, index) in opportunity.list" :key="index">
        <CustomerOpportunityCard :opportunity="opportunity" />
      </div>
    </div>

    <div class="a-center mt-30 mb-40" v-if="opportunity.list.length === 0">
      Nenhuma oportunidade.
    </div>

    <div class="a-center mb-60">
      <a-pagination
        show-size-changer
        :default-current="opportunity.pagination.page"
        :page-size.sync="opportunity.pagination.perPage"
        :total="opportunity.pagination.total"
        @change="changeOpportunityPage"
        @showSizeChange="changeOpportunityPageSize"
      >
      </a-pagination>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import opportunityMixins from "@/components/pipeline/mixins/opportunityMixins";
import CustomerOpportunityCard from "../sections/CustomerOpportunityCard.vue";

export default {
  name: "CustomerOpportunitiesTab",
  props: {
    tempCustomer: Object,
  },
  components: { CustomerOpportunityCard },
  mixins: [opportunityMixins, formatThings],
  data() {
    return {
      activeTab: "",
      optTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Etapa",
          dataIndex: "step",
          key: "step",
          width: 150,
          sorter: true,
        },
        {
          title: "Título",
          width: 200,
          dataIndex: "title",
          key: "title",
          sorter: true,
        },

        {
          title: "Valor",
          width: 120,
          key: "value",
          scopedSlots: { customRender: "value" },
          sorter: true,
        },
        {
          title: "Status",
          width: 100,
          dataIndex: "status",
          key: "status",
          sorter: true,
        },
        {
          title: "Situação",
          width: 100,
          dataIndex: "situation",
          key: "situation",
          sorter: true,
        },

        {
          title: "Usuário",
          width: 150,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 90,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.opportunity.pagination.page = 1;
    this.opportunity.pagination.perPage = 10;
    this.opportunity.filters.customers.selected = this.tempCustomer.id;
    this.opportunity.filters.situation.selected = [];
    this.opportunity.filters.status.selected = [];
    this.getOpportunities();
  },
  methods: {
    onClickChangeContractsStatus(status) {
      this.activeTab = status;
      this.opportunity.filters.status.selected = status ? [status] : [];
      this.getOpportunities();
    },
  },
};
</script>

<style lang="sass" scoped>
.filters
  button
    font-size: 11px
    padding: 0
    line-height: 1 !important
    height: auto
    color: #9999
    border: 0
    transition: .3s
  button.active
    color: #992c5f  !important
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  img
    margin-right: 5px
  h3
    font-size: 14px
    font-weight: 500
    color: #444
</style>
